// -------------------------
// --- You can add your variables to overwrite default values.
// -------------------------

// Uncomment one of the following line if you want to get appropriate theme.
//@import 'themes/red';
//@import 'themes/blue';
//@import 'themes/black';
//@import 'themes/green';
@import 'themes/custom';
$footer-bg:                          #c7081b                    !default;
$footer-text:                        #FFFFFF                    !default;
$footer-title:                       #FFCC00                    !default;
$nav-links-item-hover-bg:            #c7081b                    !default;
