@import 'buttons';
@import '../functions';
@import 'hacks';
@import 'direction';


@mixin product-card-list() {
    flex-direction: row;

    .product-card__image {
        flex-shrink: 0;
        padding: 24px;
        width: 210px;
    }
    .product-card__info {
        padding: 20px 0;

        @include direction {
            #{$padding-inline-start}: 4px;
            #{$padding-inline-end}: 4px;
        }
    }
    .product-card__name {
        font-size: 16px;
        line-height: 20px;
        flex-grow: 0;
    }
    .product-card__rating {
        margin-top: 7px;
    }
    .product-card__description {
        color: $product-card-font-alt-color;
        font-size: 15px;
        line-height: 22px;
        margin-top: 12px;
    }
    .product-card__actions {
        flex-shrink: 0;
        width: 190px;
        padding: 16px 20px;

        @include direction {
            #{$border-inline-start}: 1px solid $product-card-divider-color;
        }
    }
    .product-card__prices {
        margin-top: 16px;
        font-size: 18px;
    }
    .product-card__old-price {
        font-size: 14px;
    }
    .product-card__buttons {
        flex-wrap: wrap;
    }
    .product-card__addtocart {
        display: none;
    }
    .product-card__addtocart--list {
        display: block;
    }

    .product-card__addtocart {
        width: 100%;
    }
    .product-card__addtocart + * {
        @include direction {
            #{$margin-inline-start}: 0;
        }
    }
    .product-card__addtocart ~ * {
        margin-top: 8px;
    }
    .product-card__addtocart,
    .product-card__wishlist,
    .product-card__compare {
        @include btn-sm();
    }
    .product-card__availability {
        color: $product-card-font-muted-color;
        font-size: 14px;

        @include direction {
            #{$padding-inline-end}: 10px;
        }
    }


    .product-card__features-list {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;

        li {
            padding: 3px 0;

            @include direction {
                #{$padding-inline-start}: 12px;
                #{$padding-inline-end}: 0;
            }
        }
        li::before {
            top: 8px;
        }
    }

    @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
        .product-card__image {
            width: 180px;

            @include direction {
                #{$padding-inline-start}: 20px;
                #{$padding-inline-end}: 20px;
            }
        }
        .product-card__info {
            @include direction {
                #{$padding-inline-start}: 0;
                #{$padding-inline-end}: 20px;
            }
        }
    }

    @media (max-width: breakpoint(sm-end)) {
        flex-direction: column;

        .product-card__image {
            width: 250px;
            padding: 20px;
            margin: 0 auto;
        }
        .product-card__info {
            border-top: 1px solid $product-card-divider-color;
            padding: 20px;
        }
        .product-card__actions {
            width: auto;
            border-top: 1px solid $product-card-divider-color;
            padding: 20px;

            @include direction {
                #{$border-inline-start}: none;
            }
        }
        .product-card__buttons {
            flex-wrap: nowrap;

            .btn {
                @include btn-nl();
            }
        }
        .product-card__addtocart--list {
            width: auto;

            @include direction {
                #{$margin-inline-end}: auto;
            }
        }
    }
}


@mixin product-card-grid() {
    .product-card__image {
        padding: 18px 18px 20px;
    }
    .product-card__info {
        padding: 0 24px 0;
    }
    .product-card__actions {
        padding: 0 24px 24px;
    }
    .product-card__description {
        display: none;
    }
    .product-card__availability {
        display: none;
    }
    .product-card__features-list {
        display: none;
    }
}
@mixin product-card-grid-with-features() {
    .product-card__features-list {
        display: block;
    }
}
@mixin product-card-grid-sm() {
    .product-card__image {
        padding: 15px;
    }
    .product-card__badges-list {
        top: 16px;

        @include direction {
            #{$inset-inline-start}: 16px;
        }
    }
    .product-card__info {
        padding: 0 15px 0;
    }
    .product-card__actions {
        padding: 0 15px 15px;
    }


    .product-card__buttons .btn {
        @include btn-xs();
    }

    @media (hover: hover) {
        .product-card__buttons {
            display: none;
        }

        &:hover {
            position: relative;
            z-index: 3;
            margin-bottom: -50px;

            .product-card__buttons {
                display: flex;
            }
        }
    }

    @include only-ie() {
        .product-card__buttons {
            display: none;
        }

        &:hover {
            position: relative;
            z-index: 3;
            margin-bottom: -50px;

            .product-card__buttons {
                display: flex;
            }
        }
    }
}
@mixin product-card-grid-nl() {
    .product-card__buttons .btn {
        @include btn-sm();
    }

    @media (hover: hover) {
        .product-card__buttons {
            display: none;
        }

        &:hover {
            position: relative;
            z-index: 3;
            margin-bottom: -56px;

            .product-card__buttons {
                display: flex;
            }
        }
    }

    @include only-ie() {
        .product-card__buttons {
            display: none;
        }

        &:hover {
            position: relative;
            z-index: 3;
            margin-bottom: -56px;

            .product-card__buttons {
                display: flex;
            }
        }
    }
}
@mixin product-card-grid-lg() {
    .product-card__image {
        padding: 25px;
    }
    .product-card__info {
        padding: 0 30px 0;
        flex-grow: 0;
    }
    .product-card__name {
        font-size: 17px;
        line-height: 23px;
    }
    .product-card__actions {
        padding: 0 30px 30px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    .product-card__rating {
        margin-top: 10px;
    }
    .product-card__prices {
        margin-bottom: auto;
        font-size: 20px;
    }
}


@mixin product-card-horizontal() {
    flex-direction: row;

    .product-card__image {
        width: 120px;
        padding: 16px;
    }
    .product-card__badges-list {
        top: 10px;

        @include direction {
            #{$inset-inline-start}: 10px;
        }
    }
    .product-card__badge {
        padding: 3px 5px 2px;
    }
    .product-card__info {
        padding-top: 14px;
        padding-bottom: 42px;
        width: 100%;

        @include direction {
            #{$padding-inline-start}: 0;
            #{$padding-inline-end}: 30px;
        }
    }
    .product-card__name {
        overflow: hidden;
        max-height: 19px * 2;
    }
    .product-card__rating {
        white-space: nowrap;
    }
    .product-card__description {
        display: none;
    }
    .product-card__availability {
        display: none;
    }
    .product-card__buttons {
        display: none;
    }
    .product-card__actions {
        position: absolute;
        bottom: 18px;

        @include direction {
            #{$inset-inline-start}: 120px;
        }
    }
    .product-card__features-list {
        display: none;
    }
}
@mixin product-card-horizontal-sm() {
    .product-card__image {
        width: 110px;
    }
    .product-card__rating {
        display: none;
    }
    .product-card__actions {
        @include direction {
            #{$inset-inline-start}: 110px;
        }
    }
}
@mixin product-card-horizontal-lg() {
    $local-image-size: 216px;
    $local-image-margin: 16px;
    $local-image-padding: 24px;

    .product-card__image {
        width: $local-image-size;

        @include direction {
            #{$padding-inline-start}: $local-image-padding;
            #{$padding-inline-end}: $local-image-padding;
            #{$margin-inline-end}: $local-image-margin;
        }
    }
    .product-card__info {
        padding-top: 20px;
        padding-bottom: (82px + 26px + 12px);
    }
    .product-card__name {
        font-size: 17px;
        margin-bottom: 2px;
        flex-grow: 0;
    }
    .product-card__actions {
        align-items: center;
        bottom: 26px;

        @include direction {
            #{$inset-inline-start}: $local-image-size + $local-image-margin;
        }
    }
    .product-card__prices {
        margin-top: 0;
        font-size: 20px;

        @include direction {
            #{$margin-inline-end}: 24px;
        }
    }
    .product-card__buttons {
        display: block;
    }
}
